/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'assets/css/fonts/fonts';

body {
	padding: 0;
	font-family: 'Nunito', sans-serif;
	font-size: 1rem;
	margin: 0;
	background: #ffffff;

	--blue: #45689d;
	--blue-seccondary: #34518f;
	--blue-medium: #496fc0;
	--blue-ligth-normal: #6293fc;
	--blue-ligth: #56ccf2;
	--blue-extra-ligth: #d2e1ff;

	--black: #000000;
	--white: #ffffff;
	--red: #da615c;
	--gray-extra: #f2f2f2;
	--gray: #4f4f4f;
	--gray-medium: #828282;
	--gray-ligth: #bdbdbd;
	--gray-extra-ligth: #e8e8e8;

	*:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--black);
	margin: 0;
}

h1,
h2 {
	font-style: normal;
	font-weight: 600;
	font-size: 35px;
	line-height: 96%;
	color: var(--gray);
	padding-bottom: 20px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 23px;
	line-height: 96%;
	text-align: center;
	color: var(--gray-medium);
}

h4 {
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 96%;
	color: var(--blue);
	padding-bottom: 20px;
}

h5 {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140%;
	letter-spacing: 0.01em;
}

h6 {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.01em;
}

p {
	margin: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 160%;
}

a {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 24px;
	color: var(--black);
}

@media (min-width: 1280px) {
	h1,
	h2 {
		font-size: 40px;
	}

	h3 {
		font-size: 30px;
	}
}

@media (min-width: 1367px) {
	h1,
	h2 {
		font-size: 55px;
	}
}

@media (min-width: 1441px) {
	h1,
	h2 {
		font-size: 64px;
	}

	h3 {
		font-size: 36px;
	}
}

figure {
	margin: 0;
}

button {
	background: transparent;
	border: 0;
}

.container {
	max-width: 100%;
	width: 100%;

	&.sidebar {
		padding-left: 15px;
	}
}

.form-group {
	margin-bottom: 25px;
	position: relative;

	input,
	select {
		font-style: normal;
		font-weight: 200;
		width: 100%;
		border-radius: 5px;
		border: 1px solid var(--gray);
		outline: none;
		height: 56px;
		line-height: 1;
		font-size: 14px;
		padding: 12px 0 10px 15px;

		&:focus {
			box-shadow: none !important;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	.default {
		input,
		select {
			font-style: normal;
			font-weight: 200;
			border-radius: 5px;
			outline: none;
			line-height: 1;
			font-size: 12px;
			padding: 12px 0 10px 15px;
			border: 1px solid var(--gray-light);
			min-width: 100px;
			width: auto;
			height: 30px;

			&:focus {
				box-shadow: none !important;
			}
		}

		select {
			padding: 6px 22px 6px 6px;
		}
	}

	label {
		width: 100%;
	}

	.control {
		&-label {
			font-style: normal;
			font-weight: bold;
			background: var(--white);
			position: absolute;
			left: 10px;
			top: -7px;
			padding: 0 9px;
			outline: none;
			text-transform: uppercase;
			font-size: 10px;
			color: var(--gray-dark);
			z-index: 1;
		}

		&-error {
			color: var(--red);
			font-size: 12px;
			position: absolute;
			left: 12px;
			bottom: -12px;
		}

		@media (min-width: 560px) {
			&-label {
				font-size: 12px;
			}
		}
	}
}

.input-check {
	position: relative;
	padding-left: 33px;
	margin-bottom: 10px;

	label {
		font-size: 16px;
		line-height: 16px;

		letter-spacing: 0.4px;
		color: var(--gray);
		margin-bottom: 0;

		&:hover {
			cursor: pointer;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 1px;
		left: 0;
		height: 22px;
		width: 22px;
		border-radius: 2px;
		border: 2px solid var(--gray);

		&:after {
			content: '';
			position: absolute;
			display: none;

			left: 5px;
			top: 2px;
			width: 7px;
			height: 12px;
			border: solid var(--gray);
			border-width: 0 2px 2px 0;

			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	input[type='radio'] {
		& ~ .checkmark {
			border-radius: 50%;
		}
	}

	.control-error {
		padding: 0;
		color: var(--red);
		font-size: 12px;
		display: block;
	}

	&:hover input ~ .checkmark {
		background-color: var(--white);
	}

	input:checked ~ .checkmark:after {
		display: block;
	}

	/** hover checkbox **/
	&:hover {
		cursor: pointer;
	}
}

::placeholder {
	color: #bdbdbd;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #bdbdbd;
}

::-ms-input-placeholder {
	color: #bdbdbd;
}

.show-pass {
	position: absolute;
	right: 0;
	top: 14px;
	border: 0;
	background: transparent;

	&:after {
		content: '';
		position: absolute;
		left: 15px;
		top: 1px;
		width: 3px;
		height: 26px;
		background: #828282;
		transform: rotate(45deg);
	}

	&.active {
		&:after {
			content: none;
		}
	}

	figure {
		margin: 0;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: black !important;
	-webkit-box-shadow: 0 0 0 30px #ffffff inset;
	transition: background-color 5000s ease-in-out 0s;
}

.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: var(--blue);
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-align: center;
	font-feature-settings: 'pnum' on, 'lnum' on;
	color: var(--white);

	&:hover {
		color: var(--white);
	}

	&:focus {
		box-shadow: none;
	}

	&.outline {
		background: var(--white);
		border: 1px solid var(--blue-medium);
		border-radius: 6px;
		color: var(--blue-medium);

		&:hover {
			color: var(--blue-medium);
		}
	}

	&.disabled {
		background: var(--gray-light);
		border-color: var(--gray-light);
		color: var(--white);
		cursor: no-drop;
	}

	&-xs {
		width: 106px;
		height: 24px;
		font-size: 12px;
	}

	&-small {
		border-radius: 8px;
		width: 200px;
		height: 32px;
		font-size: 12px;
	}

	&-medium {
		width: 200px;
		height: 48px;
		border-radius: 8px;
		font-size: 14px;
	}
}

.empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	height: 50vh;
	grid-gap: 25px;

	h3 {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.02em;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: #828282;
		max-width: 380px;
	}

	&-small {
		height: auto;

		img {
			width: 50px;
		}

		h3 {
			font-size: 16px;
		}
	}
}

.toast {
	$this: &;

	&-container {
		.ngx-toastr {
			border-left: 5px solid var(--blue-medium);
			//border-radius: 0 3px 3px 0 !important;
			color: var(--black) !important;
			background: var(--white);
			box-shadow: 0 2px 13px rgba(0, 0, 0, 0.27) !important;

			&.toast-info {
				border-color: var(--yellow);
			}

			&.toast-success {
				border-color: var(--green);
			}

			&.toast-error {
				border-color: var(--red);
			}

			.toast-title {
				font-weight: 600;
				color: var(--gray);
			}

			.toast-message {
				font-weight: 300;
				color: var(--gray);
			}

			button {
				color: var(--gray);
			}
		}
	}

	&-progress {
		background-color: var(--gray-light) !important;
	}
}

.wrapper-search {
	display: flex;
	align-items: center;
	grid-gap: 15px;
	padding-bottom: 10px;

	p {
		margin: 0;
	}

	::ng-deep .form-group {
		margin-bottom: 0;
	}
}

.custom-scroll {
	&::-webkit-scrollbar {
		width: 3px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--gray-extra-ligth);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--gray-ligth);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--gray);
	}
}

.modal {
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
	padding: 15px;

	&-content {
		border: 0;
	}

	&-dialog {
		height: 100%;
		align-items: center;
		display: flex;
		margin: auto;
	}

	&-body {
		padding: 24px;

		h5 {
			margin-bottom: 15px;
		}
	}

	&-footer {
		border: 0;
		padding: 0 24px 24px;

		.btn {
			font-size: 12px;
			height: 24px;

			&.outline {
				border: 0;
			}
		}
	}
}

.cursor-pointer {
	cursor: pointer !important;
}

.card {
	border-radius: 12px;
}

.wrapper {
	$this: &;

	&-video {
		text-align: center;
		position: relative;

		&.fullScreen {
			text-align: center;
			position: fixed;
			z-index: 9999;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			max-height: 100%;
			background: rgba(0, 0, 0, 0.5);

			video {
				height: 100%;
				max-height: 100%;
			}
		}

		canvas {
			position: fixed;
			left: -999rem;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}

		video {
			width: 100%;
			//height: 580px;
			//max-height: 580px;
			background: #000;
		}

		button.hover {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: calc(100% - 7px);
			background: rgba(0, 0, 0, 0.74);
			padding: 0;
			display: flex;
			gap: 15px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-style: normal;
			font-weight: 500;
			font-size: 1.406vw;
			line-height: 96%;
			color: #ffffff;
			opacity: 0;
			transition: 0.3s;

			img {
				max-width: 390px;
				width: 20.313vw;
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	&-config {
		position: absolute;
		top: 10px;
		right: 10px;

		button {
			width: 30px;
			height: 30px;
			padding: 0;

			img {
				max-width: 100%;
			}
		}

		&.bottom {
			top: auto;
			bottom: 15px;
		}
	}

	&:hover {
		#{$this}-controls {
			transition: 0.5s;
		}
	}

	.content-config {
		background: var(--white);
		box-shadow: 0 0 10px rgba(51, 51, 51, 0.2);
		border-radius: 6px;
		max-width: 380px;
		width: 100%;
		position: absolute;
		right: 0;
		top: 48px;
		z-index: 2;

		#{$this}-select {
			padding: 9px;
			border-bottom: 1px solid #e0e0e0;

			& > div {
				padding: 6px 15px;
				width: 100%;
				transition: 0.3s;
				display: flex;
				gap: 15px;

				&:hover {
					background: #f2f2f2;
				}

				figure {
					& ~ div {
						width: 100%;
					}
				}
			}

			&:first-child {
				& > div {
					border-radius: 6px 6px 0 0;
				}
			}

			&:last-child {
				border-bottom: 0;

				& > div {
					border-radius: 0 0 6px 6px;
				}
			}

			label {
				display: block;
			}

			select {
				border: 0;
				display: block;
				width: 100%;
				outline: none;
				background: transparent;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				color: #828282;
			}
		}

		@media (min-width: 560px) {
			right: 10px;
		}
	}
}

.wrapper {
	h2 {
		text-align: left;
	}
	&-content {
		position: relative;
		display: flex;
		//align-items: center;
		gap: 25px;

		@media (min-width: 1024px) {
			gap: 50px;
		}

		@media (min-width: 1282px) {
			gap: 80px;
		}
	}
	h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 96%;
		color: var(--blue-medium);
	}

	.devices {
		align-self: baseline;

		&.no-data {
			& > div {
				border-color: var(--yellow);
			}
		}

		& > div {
			color: var(--gray-medium);
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 1.2;
			border: 2px solid var(--gray-medium);
			border-radius: 17px;
			margin-bottom: 15px;
			padding: 10px 25px;
			max-width: 320px;
			min-width: 200px;
			min-height: 60px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&.active {
				background: var(--blue-medium);
				color: var(--white);
				border: 2px solid var(--blue-medium);
			}

			@media (min-width: 1280px) {
				min-width: 260px;
				max-width: 260px;
			}
		}
	}

	.content {
		max-width: 800px;
		width: 100%;
		background: #fefefe;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		padding: 0 60px;
		align-self: center;

		&.sh-0 {
			box-shadow: none;
		}
	}
}
